/** @jsx jsx */
import { jsx } from "theme-ui"
import Layout from "../components/layout"
import ProductsMainArea from "../components/ProductsMainArea"
import { graphql, Link } from "gatsby"

import Img from "gatsby-image"
import FadeCarsoule from "../components/FadeCarsoule"
import Arrow from "../components/Arrow"

const Product = ({
  data: {
    markdownRemark: {
      html,
      frontmatter: { title, lang, description, overview, images, youtubeUrl },
    },
  },
}) => (
  <Layout title={title} lang={lang}>
    <ProductsMainArea title={title} description={description} />
    <div className="b-homepage-content">
      <div className="b-layer-main">
        <Arrow />
        <div className="pdGallery container mb-5">
          <div className="row">
            <div sx={{ width: [500, 400, 500, 600] }}>
              <h3
                sx={{
                  borderInlineStart: "10px solid",
                  paddingInlineStart: 30,
                  paddingInlineEnd: 60,
                  boxSizing: "border-box",
                  borderColor: "#f2f2f2",
                }}
                className="quote"
                dangerouslySetInnerHTML={{ __html: overview }}
              />
            </div>
            <div
              sx={{
                m: "auto",
                boxShadow: "0 0 20px rgba(0, 0, 0, 0.4)",
              }}
            >
              {images.length > 0 ? (
                <FadeCarsoule
                  autoPlay
                  showStatus={false}
                  infiniteLoop
                  interval={2500}
                  showThumbs={false}
                >
                  {images.map(({ image: { childImageSharp } }, index) => (
                    <div sx={{ width: [400, 500] }}>
                      <Img key={index} fluid={childImageSharp.fluid} />
                    </div>
                  ))}
                </FadeCarsoule>
              ) : null}
            </div>
          </div>
        </div>

        <div
          className="container mb-5"
          dangerouslySetInnerHTML={{ __html: html }}
        />

        <div className="container">
          <hr />
          <div
            sx={{
              padding: "30px 0",
            }}
          >
            <div className="row">
              {youtubeUrl && (
                <div className="col-md-7">
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      src={youtubeUrl}
                      title="welcome video"
                      frameBorder="0"
                    ></iframe>
                  </div>
                </div>
              )}
              <div className="col-md-5">
                <div
                  sx={{
                    paddingTop: 40,
                  }}
                >
                  <h5>Product Enquiry</h5>
                  <p>
                    If you're ready to generate some buzz for your idea or
                    brand, Hell yeah.. let's meet!
                  </p>
                  <Link className="BtnAction" to="/contact">
                    Get in touch
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Product

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        lang
        description
        overview
        youtubeUrl

        images {
          image {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`
