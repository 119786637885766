/** @jsx jsx */
import { useState, useRef, Fragment } from "react"
import PropTypes from "prop-types"

import { Flex, jsx } from "theme-ui"
import useInterval from "../../hooks/useInterval"

export default function FadeCarsoule({
  children,
  slideStyle,
  nextArrow,
  nextArrowStyle,
  prevArrow,
  prevArrowStyle,
  activeSlideStyle,
  autoplay,
  duration,
}) {
  const [cindex, setCindex] = useState(0)
  const [offsetHeight, setOffsetHeight] = useState(false)
  const wrapper = useRef()
  const list = children

  const nextIndex = cindex + 1 >= children.length ? 0 : cindex + 1
  const prevIndex = cindex - 1 >= 0 ? cindex - 1 : children.length - 1
  const currentSlide = list[cindex]

  const handleNext = () => setCindex(nextIndex)

  const handlePrev = () => setCindex(prevIndex)

  useInterval(() => handleNext(), duration)

  return (
    <Fragment>
      <div ref={wrapper} style={{ position: "relative", overflow: "hidden" }}>
        <div style={{ ...slideStyle, ...activeSlideStyle }}>{currentSlide}</div>
        <Flex
          style={{
            position: "absolute",
            direction: "rtl",
            left: 0,
            right: 0,
            top: `${offsetHeight / 2 - 15}px`,
          }}
          sx={{ justifyContent: "space-between" }}
        >
          <div
            sx={prevArrowStyle}
            role="button"
            tabIndex={0}
            onKeyPress={handlePrev}
            onClick={handlePrev}
          >
            {prevArrow}
          </div>
          <div
            sx={nextArrowStyle}
            role="button"
            tabIndex={0}
            onKeyPress={handleNext}
            onClick={handleNext}
          >
            {nextArrow}
          </div>
        </Flex>
      </div>
    </Fragment>
  )
}

FadeCarsoule.defaultProps = {
  duration: 3000,
  autoplay: false,
  nextArrow: null,
  prevArrow: null,
  nextArrowStyle: {},
  prevArrowStyle: {},
}

FadeCarsoule.propTypes = {
  autoplay: PropTypes.bool,
  duration: PropTypes.number,
  nextArrow: PropTypes.func,
  prevArrow: PropTypes.func,
  nextArrowStyle: PropTypes.shape(PropTypes.object.isRequired),
  prevArrowStyle: PropTypes.shape(PropTypes.object.isRequired),
}
